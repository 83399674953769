import {
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	reauthenticateWithCredential,
	type User,
	signOut,
	EmailAuthProvider
} from 'firebase/auth'

import { getClientAuth } from './app'
import { toast } from 'svelte-sonner'
import { goto } from '$app/navigation'

/*
Possible Auth actions:
sendEmailVerification 
updatePassword
sendPasswordResetEmail

Customize emails and SMS:
https://support.google.com/firebase/answer/7000714

*/

export async function refreshSessionCookie(user: User, forceRefresh?: boolean): Promise<User> {
	// TODO - what if we cant get idToken? I.e. on startup after > 1 hour
	let idToken
	try {
		idToken = await user.getIdToken(forceRefresh)
	} catch (error) {
		console.log('Failed to get id token', error)
		throw error
	}
	// Session login endpoint is queried and the session cookie is set.
	// CSRF protection should be taken into account.
	// ...

	// Get cookie the svelte-way - use samesite: strict instead
	/* 	const csrfToken = getCookie('csrfToken') */
	// Post IdToken to api endpoint to complete the sign-in
	try {
		await fetch('/api/authenticate', {
			method: 'POST',
			body: JSON.stringify({ idToken }),
			headers: {
				'content-type': 'application/json'
			}
		})
	} catch (error) {
		console.log('Failed to authenticate', error)
		throw error
	}

	// Remember to handle errors caused by lacking api authorization.
	// Such errors should trigger refreshCredentials

	return user
}

export async function signUpEmail(email: string, password: string): Promise<User> {
	const auth = getClientAuth()
	const { user } = await createUserWithEmailAndPassword(auth, email, password)
	return refreshSessionCookie(user)
}

export async function signInEmail(email: string, password: string): Promise<User> {
	const auth = getClientAuth()
	const { user } = await signInWithEmailAndPassword(auth, email, password)
	return refreshSessionCookie(user)
}

export async function reauthenticateEmail(
	email: string,
	password: string
): Promise<User | undefined> {
	const auth = getClientAuth()
	if (auth.currentUser) {
		const credential = EmailAuthProvider.credential(email, password)
		const { user } = await reauthenticateWithCredential(auth.currentUser, credential)
		return refreshSessionCookie(user)
	}
}

export async function logout(toastMessage = 'Du har logget ut', disableRedirect?: boolean) {
	try {
		localStorage.clear()
		await fetch('/logout', {
			method: 'POST',
			headers: {
				'content-type': 'application/json'
			}
		})
		const auth = getClientAuth()
		const redirect = auth.currentUser?.providerData?.some(
			(provider) => provider.providerId === 'password'
		)
			? '/login'
			: '/telefon'
		await signOut(auth)
		if (toastMessage) {
			toast.success(toastMessage)
		}
		if (!disableRedirect) goto(redirect)
	} catch (error) {
		console.log('Failed to sign out (with logout)', error)
		toast.success('Klarte ikke logge ut. Vennligst prøv igjen senere')
	}
}
